import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/arrhythmia-care-banner.jpg';

import AccordionTitle from '../components/elements/accordion/accordionTitle';
import Accordion from '../components/elements/accordion';

export default function ArrhythmiaConditionTreatment({ data }) {
  const {
    cardiac_condition_acc,
    cardiac_acc_title,
  } = data.allStrapiCardiacCondition.nodes[0];

  return (
    <Layout>
      <Seo
        title="Arrhythmia Care condition"
        description="Together with Southlake Regional Cardiac Care Program, we’ve cared for patients with arrhythmias for decades."
      />
      <Hero title="Arrhythmia Care" banner={Banner} />
      <div className="   mt-40 pb-52 flex flex-col medical-bg">
        <AccordionTitle
          title={cardiac_acc_title?.title}
          subTitle={cardiac_acc_title?.subtitle}
        >
          {cardiac_condition_acc.length > 0 && (
            <Accordion
              data={cardiac_condition_acc}
              itemButtonClass="accordion__button__custom accordion__button"
            />
          )}
        </AccordionTitle>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query Cardiac {
    allStrapiCardiacCondition {
      nodes {
        cardiac_condition_acc {
          id
          title
          description
        }
        cardiac_acc_title {
          id
          title
          subtitle
        }
      }
    }
  }
`;
